/* Provide sufficient contrast against white background */
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  background: rgb(255, 255, 255);
  color: #575a5e;
  font-size: 18px;
}

header {
  padding-bottom: 135px;
}

a {
  color: #d4213d;
}

a:not([href]) {
  color: #d4213d;
  cursor: pointer;
}

a:not([href]):hover {
  color: #d4213d;
  cursor: pointer;
}

.bm-item-list a:not([href]) {
  color: #ffffff;
  cursor: pointer;
}

.bm-item-list a:not([href]):hover {
  color: #ffffff;
  cursor: pointer;
}

a:hover {
  color: #d4213d;
}

.btn {
  padding: 0.25rem 2rem;
}

.btn-primary {
  color: #fff;
  background-color: #d4213d;
  border-color: #d4213d;
  font-size: 22px;
}

.btn-primary:hover {
  background-color: #e5576d;
  border-color: #d4213d;
}

.btn-secondary {
  color: #575a5e;
  background-color: #f2f2f2;
  border-color: #575a5e;
  font-size: 18px;
  font-weight: bold;
}

.btn-blue {
  color: #fff;
  background-color: #003166;
  border-color: #003166;
  font-size: 22px;
}

.btn-blue:hover {
  background-color: #003166c5;
  border-color: #003166c5;
}

.text-primary {
  color: #575a5e;
}

.text-blue {
  color: #003166;
}

.text-blue-secondary {
  color: #003166;
}

.text-grey {
  color: #575a5e;
}

.text-grey-secondary {
  color: #575a5e;
}

.text-strong {
  font-weight: bold !important;
}

.social-buttons {
  text-align: center;
}

.social-buttons a:hover {
  text-decoration: none;
}

.social-buttons a {
  font-size: 18px;
  color: #575a5e;
  margin: 0 10px;
  font-weight: bold;
}

.social-buttons a:hover {
  font-size: 18px;
  color: #575a5e;
  margin: 0 10px;
  font-weight: bold;
}

.button {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #d4213d;
}

h1,
.h1 {
  font-size: 42px !important;
  font-weight: 700 !important;
  color: #d4213d !important;
  text-align: center !important;
  margin-bottom: 1em;
}

h2,
.h2 {
  font-size: 30px;
  font-weight: 700;
  color: #003166;
}

h3,
.h3 {
  font-size: 24px;
  font-weight: 700;
  color: #d4213d;
}

h4,
.h4 {
  color: #575a5e;
  font-weight: 700;
  font-size: 18px;
}

h5,
.h5 {
  font-size: 18px;
  color: #003166;
  font-weight: 700;
}

h5 a,
.h5 a,
h5 a:hover,
.h5 a:hover {
  color: #003166;
}

h6,
.h6 {
  font-size: 24px;
}

.bottomSection {
  margin-top: 30px;
  padding: 30px 0px;
  font-size: 16px;
  font-weight: normal;
  background-color: #dddedf;
  color: #003166;
}

.bottomSection h3 {
  color: #003166;
}

.bottomSection a,
.bottomSection a:hover {
  color: #003166;
}

.btn {
  border-radius: 0px;
}

.btn-default {
  font-size: 16px;
  color: #003166;
  background-color: #f2f2f2;
  border: solid 1px #003166;
}

.btn-empty {
  padding: 0 !important;
  font-size: 20px;
}

.carousel-item img {
  width: 100%;
}

/* calendar */

table.calendar-day {
  font-size: 24px;
  text-align: center;
  display: inline;
}

table.calendar-day th {
  padding: 20px 15px;
}

table.calendar-day td {
  padding: 12px 15px;
}

td.calendar-day {
  border: 1px solid #575a5e;
}

td.empty {
  border: none;
}

td.sunday {
  background-color: #003166;
  color: #fff;
}

td.sunday {
  background-color: #003166;
  color: #fff;
}

td.today {
  background-color: #ffd51a;
  color: #575a5e;
}

td.active-day,
td.active-day a {
  background-color: #d4213d;
  color: #fff;
}

/* members */

.member-box-wrapper {
  height: 422px;
}

.member-box {
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  transition: all 0.2s ease-in-out;
}

.member-box:hover {
  box-shadow: -5px 10px 23px -4px rgba(24, 27, 32, 0.15);
  transform: scale(1.01);
}

.member-box-body {
  padding: 15px;
  min-height: 272px;
  text-align: center;
}

.member-box-footer {
  background-color: #fff;
  border-top: 1px solid #a7a9ac;
  padding: 15px;
  line-height: 1.2;
  border-radius: 0px 0px 0px 0px;
  min-height: 150px;
}

.member-box-footer p {
  margin-bottom: 0;
}

.member-box-footer-body {
  min-height: 70px;
}

.member-box-footer h4 {
  font-size: 14px;
}

.member-box-footer a {
  font-size: 16px;
  color: #303c49;
  font-weight: bold;
}

.member-link {
  color: #003166;
  font-size: 16px;
  color: #303c49;
  font-weight: bold;
}

.member-golden {
  padding: 7px 25px;
  background-color: #ffd51a;
  color: #575a5e;
  font-size: 14px;
}

.member-text p,
.member-text a,
.member-text {
  font-size: 16px;
  color: #575a5e;
}

.member-tag {
  color: #003166;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form-group {
  margin-bottom: 0px;
}

.form-control {
  font-size: 1em !important;
  border-radius: 0;
}

.news-slice h5 {
  font-size: 14px;
  font-weight: normal;
}

h5.news-category {
  font-size: 14px !important;
  font-weight: normal !important;
}

@media (min-width: 1200px) {
  .datetime-calendar-small,
  .datetime-calendar-small table {
    font-size: 90% !important;
    text-align: center;
  }

  .datetime-calendar-large,
  .datetime-calendar-large table {
    font-size: 140% !important;
    text-align: center;
  }

  .news-slice {
    border-left: solid 7px #dddedf;
    margin: 15px 0px 15px 30px;
    padding-left: 30px;
  }
}

@media (max-width: 1200px) and (min-width: 922px) {
  .datetime-calendar-small,
  .datetime-calendar-small table {
    font-size: 90% !important;
    text-align: center;
  }

  .datetime-calendar-large,
  .datetime-calendar-large table {
    font-size: 140% !important;
    text-align: center;
  }

  .news-slice {
    border-left: solid 7px #dddedf;
    margin: 15px 30px 15px 0px;
    padding-left: 30px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .xs-text-center {
    text-align: center !important;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .xs-text-center {
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  .xs-text-center {
    text-align: center !important;
  }

  table.calendar-day th {
    padding: 18px 10px;
  }

  table.calendar-day td {
    padding: 10px 10px;
  }

  table.calendar-day {
    font-size: 18px;
    text-align: center;
    display: inline;
  }
  h1,
  .h1 {
    font-size: 36px;
  }
  .datetime-calendar-normal {
    margin-bottom: 50px;
  }

  .datetime-calendar-small,
  .datetime-calendar-small table {
    font-size: 80% !important;
    text-align: center;
  }

  .datetime-calendar-large {
    margin-bottom: 30px;
  }
}
